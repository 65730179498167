// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebaseConfig: {
    apiKey: 'AIzaSyBHMfP27QIfxG8NUgFpu4r-RYRihJqraHc',
    authDomain: 'almax---prd.firebaseapp.com',
    databaseURL: 'https://almax---prd.firebaseio.com',
    projectId: 'almax---prd',
    storageBucket: 'almax---prd.appspot.com',
    messagingSenderId: '1088456151094',
    appId: '1:1088456151094:web:8eabdd1e8c796edb028897'
  },
  catalogEndpoint: 'https://catalogomm12.com.br:9000',
  engineEndpoint: 'https://catalogomm12.com.br:5201',
  forceMobileLogin: false
};
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
