import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NameSurnamePipe } from './name-surname.pipe';
import { FirstNamePipe } from './first-name.pipe';
import { LastNamePipe } from './last-name.pipe';
import { FixedPipe } from './fixed.pipe';

@NgModule({
  imports: [
    CommonModule
  ],
  declarations: [NameSurnamePipe, FirstNamePipe, LastNamePipe, FixedPipe],
  exports: [NameSurnamePipe, FirstNamePipe, LastNamePipe, FixedPipe]
})
export class PipesModule { }
