import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nameSurname'
})
export class NameSurnamePipe implements PipeTransform {

  transform(name: string): any {
    // Retorna o primeiro a o último nome do usuário.
    const nameSplitArray = name.split(/(\s+)/).filter((e) => e.trim().length > 0);
    return nameSplitArray[0] + ' ' + nameSplitArray[nameSplitArray.length - 1];
  }

}
