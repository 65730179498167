import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'firstName'
})
export class FirstNamePipe implements PipeTransform {

  transform(name: string): any {
    // Retorna o primeiro a o último nome do usuário.
    if (name) {
      const nameSplitArray = name.split(/(\s+)/).filter((e) => e.trim().length > 0);
      return nameSplitArray[0];
    } else {
      return '';
    }
  }

}
