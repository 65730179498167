/* eslint-disable max-len */
import { UtilsService } from './services/utils.service';
import { Component, NgZone } from '@angular/core';
import * as $ from 'jquery';
import { AlertController, Platform, ToastController } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { environment } from 'src/environments/environment';
import { BehaviorSubject } from 'rxjs';
import { CodePush, InstallMode, SyncStatus } from '@ionic-native/code-push/ngx';
import { Loading } from 'notiflix';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss']
})
export class AppComponent {

  public static menuClick = new BehaviorSubject(false);
  public tabActive = 1;
  public showFooter = false;
  public platformIsWeb = false;
  public menuIsOpen = false;
  public currentRoute = '';
  public routesWithoutFooter = ['', '/', '/fale-conosco', '/splash', '/login', '/splash', '/cadastro', '/admin'];
  public footerRoutes = ['/home', '/perfil', '/extrato', '/noticias', '/carrinho'];

  public appPages: any = [
    // {
    //   name: 'Home',
    //   url: '/home',
    //   icon: '../assets/imgs/menu/icon1.svg',
    //   iconActive: '../assets/imgs/menu/icon1Active.svg'
    // },
    // {
    //   name: 'Enviar Notas Fiscais',
    //   url: '/enviar-nota',
    //   icon: '../assets/imgs/menu/icon2.svg',
    //   iconActive: '../assets/imgs/menu/icon2Active.svg'
    // },
    // {
    //   name: 'Resgatar Prêmios',
    //   url: '/resgate',
    //   icon: '../assets/imgs/menu/icon3.svg',
    //   iconActive: '../assets/imgs/menu/icon3Active.svg'
    // },
    // {
    //   name: 'Cadastrar Clientes',
    //   url: '/cadastrar-cliente',
    //   icon: '../assets/imgs/menu/icon4.svg',
    //   iconActive: '../assets/imgs/menu/icon4Active.svg'
    // },
    // {
    //   name: 'Seu Extrato',
    //   url: '/extrato',
    //   icon: '../assets/imgs/menu/icon5.svg',
    //   iconActive: '../assets/imgs/menu/icon5Active.svg'
    // },
    // {
    //   name: 'Notícias',
    //   url: '/noticias',
    //   icon: '../assets/imgs/menu/icon6.svg',
    //   iconActive: '../assets/imgs/menu/icon6Active.svg'
    // },
    // {
    //   name: 'Academia Almax Mais',
    //   url: '/academia',
    //   icon: '../assets/imgs/menu/icon7.svg',
    //   iconActive: '../assets/imgs/menu/icon7Active.svg'
    // },
    // {
    //   name: 'Calcular Benefícios',
    //   url: '/calculadora',
    //   icon: '../assets/imgs/menu/icon8.svg',
    //   iconActive: '../assets/imgs/menu/icon8Active.svg'
    // },
    // {
    //   name: 'Consultar Lubrificantes',
    //   url: '/consulta',
    //   icon: '../assets/imgs/menu/icon9.svg',
    //   iconActive: '../assets/imgs/menu/icon9Active.svg'
    // },
    // {
    //   name: 'Etiqueta de Troca de Óleo',
    //   url: '/etiqueta',
    //   icon: '../assets/imgs/menu/icon10.svg',
    //   iconActive: '../assets/imgs/menu/icon10Active.svg'
    // },
    // {
    //   name: 'Perfil',
    //   url: '/perfil',
    //   icon: '../assets/imgs/menu/icon11.svg',
    //   iconActive: '../assets/imgs/menu/icon11Active.svg'
    // },
    // {
    //   name: 'Carrinho',
    //   url: '/carrinho',
    //   icon: '../assets/imgs/menu/icon12.svg',
    //   iconActive: '../assets/imgs/menu/icon12Active.svg'
    // },
    // {
    //   name: 'Meus Pedidos',
    //   url: '/pedidos',
    //   icon: '../assets/imgs/menu/icon13.svg',
    //   iconActive: '../assets/imgs/menu/icon13Active.svg'
    // },
    // {
    //   name: 'Fale Conosco',
    //   url: '/fale-conosco-logado',
    //   icon: '../assets/imgs/menu/icon14.svg',
    //   iconActive: '../assets/imgs/menu/icon14Active.svg'
    // },
    // {
    //   name: 'Regulamento',
    //   url: '/regulamento',
    //   icon: '../assets/imgs/menu/icon15.svg',
    //   iconActive: '../assets/imgs/menu/icon15Active.svg'
    // },
    // {
    //   name: 'Mecânica',
    //   url: '/mecanica',
    //   icon: '../assets/imgs/menu/icon16.svg',
    //   iconActive: '../assets/imgs/menu/icon16Active.svg'
    // }
  ];
  public userData: any = {};
  public versionAndroid = 1.09;
  public versionIos = 1.09;
  public canShowContent = true;
  public isNext = false;
  public isPopup = false;
  public generalArr: any = [];
  public answerArr: any = [];
  public starRating = 1;
  public answerRating1 = 0;
  public answerRating2 = 0;
  public answerRating3 = 0;
  public answerRating4 = 0;
  public loading: any = false;
  public idGeneralRating;
  isWeb: boolean;
  progress: number;

  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private router: Router,
    public auth: AuthService,
    private zone: NgZone,
    private db: AngularFirestore,
    private utils: UtilsService,
    private fire: AngularFireAuth,
    private codePush: CodePush,
    private alertCtrl: AlertController
  ) {
    this.initializeApp();
    this.platformIsWeb = this.utils.platformIsWeb();
    this.isWeb = !environment.forceMobileLogin;
    this.fire.onAuthStateChanged(usr => {
      this.auth.getUserData().then((dados: any) => {
        if (dados && usr) {
          this.zone.run(() => {
            this.userData = dados;
            console.log(dados);

            if (!this.userData.hasRated) {
              // console.log("Não respondeu: ", this.userData.hasRated);
              this.isPopup = true;
            }

            if (dados.userType === 'Caminhoneiro' || dados.userType === 'Frotista' || dados.userType === 'Comprador') {
              this.appPages = [
                {
                  name: 'Home',
                  url: '/home',
                  icon: '../assets/imgs/menu/icon1.svg',
                  iconActive: '../assets/imgs/menu/icon1Active.svg'
                },
                {
                  name: 'Resgatar Prêmios',
                  url: '/resgate',
                  icon: '../assets/imgs/menu/icon3.svg',
                  iconActive: '../assets/imgs/menu/icon3Active.svg'
                },
                {
                  name: 'Seu Extrato',
                  url: '/extrato',
                  icon: '../assets/imgs/menu/icon5.svg',
                  iconActive: '../assets/imgs/menu/icon5Active.svg'
                },
                {
                  name: 'Notícias',
                  url: '/noticias',
                  icon: '../assets/imgs/menu/icon6.svg',
                  iconActive: '../assets/imgs/menu/icon6Active.svg'
                },
                {
                  name: 'Perfil',
                  url: '/perfil',
                  icon: '../assets/imgs/menu/icon11.svg',
                  iconActive: '../assets/imgs/menu/icon11Active.svg'
                },
                {
                  name: 'Fale Conosco',
                  url: '/fale-conosco-logado',
                  icon: '../assets/imgs/menu/icon14.svg',
                  iconActive: '../assets/imgs/menu/icon14Active.svg'
                },
                {
                  name: 'Regulamento',
                  url: '/regulamento',
                  icon: '../assets/imgs/menu/icon15.svg',
                  iconActive: '../assets/imgs/menu/icon15Active.svg'
                },
                {
                  name: 'Mecânica',
                  url: '/mecanica',
                  icon: '../assets/imgs/menu/icon16.svg',
                  iconActive: '../assets/imgs/menu/icon16Active.svg'
                }
              ];
            } else if (dados.userType === 'Atendente' && !dados.userVendor) {
              this.appPages = [
                {
                  name: 'Home',
                  url: '/home',
                  icon: '../assets/imgs/menu/icon1.svg',
                  iconActive: '../assets/imgs/menu/icon1Active.svg'
                },
                {
                  name: 'Enviar Notas Fiscais',
                  url: '/enviar-nota',
                  icon: '../assets/imgs/menu/icon2.svg',
                  iconActive: '../assets/imgs/menu/icon2Active.svg'
                },
                {
                  name: 'Resgatar Prêmios',
                  url: '/resgate',
                  icon: '../assets/imgs/menu/icon3.svg',
                  iconActive: '../assets/imgs/menu/icon3Active.svg'
                },
                {
                  name: 'Cadastrar Clientes',
                  url: '/cadastro-cliente',
                  icon: '../assets/imgs/menu/icon4.svg',
                  iconActive: '../assets/imgs/menu/icon4Active.svg'
                },
                {
                  name: 'Seu Extrato',
                  url: '/extrato',
                  icon: '../assets/imgs/menu/icon5.svg',
                  iconActive: '../assets/imgs/menu/icon5Active.svg'
                },
                {
                  name: 'Notícias',
                  url: '/noticias',
                  icon: '../assets/imgs/menu/icon6.svg',
                  iconActive: '../assets/imgs/menu/icon6Active.svg'
                },
                {
                  name: 'Perfil',
                  url: '/perfil',
                  icon: '../assets/imgs/menu/icon11.svg',
                  iconActive: '../assets/imgs/menu/icon11Active.svg'
                },
                {
                  name: 'Fale Conosco',
                  url: '/fale-conosco-logado',
                  icon: '../assets/imgs/menu/icon14.svg',
                  iconActive: '../assets/imgs/menu/icon14Active.svg'
                },
                {
                  name: 'Regulamento',
                  url: '/regulamento',
                  icon: '../assets/imgs/menu/icon15.svg',
                  iconActive: '../assets/imgs/menu/icon15Active.svg'
                },
                {
                  name: 'Mecânica',
                  url: '/mecanica',
                  icon: '../assets/imgs/menu/icon16.svg',
                  iconActive: '../assets/imgs/menu/icon16Active.svg'
                }
              ];
            } else if (dados.userType === 'Atendente' && dados.userVendor) {
              this.appPages = [
                {
                  name: 'Home',
                  url: '/home',
                  icon: '../assets/imgs/menu/icon1.svg',
                  iconActive: '../assets/imgs/menu/icon1Active.svg'
                },
                {
                  name: 'Ranking Geral',
                  url: '/ranking',
                  icon: '../assets/imgs/menu/ranking.svg',
                  iconActive: '../assets/imgs/menu/rankingActive.svg'
                },
                {
                  name: 'Resgatar Prêmios',
                  url: '/resgate',
                  icon: '../assets/imgs/menu/icon3.svg',
                  iconActive: '../assets/imgs/menu/icon3Active.svg'
                },
                {
                  name: 'Seu Extrato',
                  url: '/extrato',
                  icon: '../assets/imgs/menu/icon5.svg',
                  iconActive: '../assets/imgs/menu/icon5Active.svg'
                },
                {
                  name: 'Perfil',
                  url: '/perfil',
                  icon: '../assets/imgs/menu/icon11.svg',
                  iconActive: '../assets/imgs/menu/icon11Active.svg'
                },
                {
                  name: 'Fale Conosco',
                  url: '/fale-conosco-logado',
                  icon: '../assets/imgs/menu/icon14.svg',
                  iconActive: '../assets/imgs/menu/icon14Active.svg'
                },
                {
                  name: 'Regulamento',
                  url: '/regulamento',
                  icon: '../assets/imgs/menu/icon15.svg',
                  iconActive: '../assets/imgs/menu/icon15Active.svg'
                }
              ];
            } else if (dados.userType === 'StationAdmin') {
              this.appPages = [
                {
                  name: 'Home',
                  url: '/home',
                  icon: '../assets/imgs/menu/icon1.svg',
                  iconActive: '../assets/imgs/menu/icon1Active.svg'
                },
                {
                  name: 'Cadastrar Funcionários',
                  url: '/cadastro-atendente',
                  icon: '../assets/imgs/menu/icon4.svg',
                  iconActive: '../assets/imgs/menu/icon4Active.svg'
                },
                {
                  name: 'Notícias',
                  url: '/noticias',
                  icon: '../assets/imgs/menu/icon6.svg',
                  iconActive: '../assets/imgs/menu/icon6Active.svg'
                },
                {
                  name: 'De-Para',
                  url: '/depara',
                  icon: '../assets/imgs/menu/icon10.svg',
                  iconActive: '../assets/imgs/menu/icon10Active.svg'
                },
                {
                  name: 'Perfil',
                  url: '/perfil',
                  icon: '../assets/imgs/menu/icon11.svg',
                  iconActive: '../assets/imgs/menu/icon11Active.svg'
                },
                {
                  name: 'Fale Conosco',
                  url: '/fale-conosco-logado',
                  icon: '../assets/imgs/menu/icon14.svg',
                  iconActive: '../assets/imgs/menu/icon14Active.svg'
                },
                {
                  name: 'Regulamento',
                  url: '/regulamento',
                  icon: '../assets/imgs/menu/icon15.svg',
                  iconActive: '../assets/imgs/menu/icon15Active.svg'
                },
                {
                  name: 'Mecânica',
                  url: '/mecanica',
                  icon: '../assets/imgs/menu/icon16.svg',
                  iconActive: '../assets/imgs/menu/icon16Active.svg'
                },
                {
                  name: 'Relatórios',
                  url: '/relatorios',
                  icon: '../assets/imgs/menu/icon15.svg',
                  iconActive: '../assets/imgs/menu/icon15Active.svg'
                }];
            }
          });
        }
        if (environment.forceMobileLogin) {
          this.db.collection('ratingQuestions').ref
            .where('active', '==', true)
            .where('userType', 'array-contains', this.userData.userType)
            .get().then(doc => {
              this.answerArr = [];
              doc.docs.forEach(resp => {
                if (resp.get('id') === 'generalRating') {
                  this.generalArr = resp.data();
                  // console.log(this.generalArr);
                } else {
                  this.answerArr.push(resp.data());
                  // console.log("Ans1: " , this.answerArr);
                }
              });

              if (this.answerArr.length > 4) {
                this.answerArr = this.answerArr.slice(-4);
                // console.log("Ans1: " , this.answerArr);
              }
            });
        }
      });
    });

    router.events.subscribe((val: any) => {
      if (val.navigationTrigger) {
        this.fire.onAuthStateChanged(user => {
          const id = user?.email ? true : false;
          if (val) {
            if (val.url && val.url !== '/splash' && val.url !== '' && val.url !== '/') {
              this.currentRoute = val.url;
              // Verifica se a rota vai esconder o footer
              if (this.routesWithoutFooter.indexOf(val.url) > -1) {
                if (id && environment.forceMobileLogin) {
                  console.log('here2');
                  this.router.navigate(['home']);
                }
                this.showFooter = false;
              }
              else if ((this.routesWithoutFooter.indexOf(val.url) === -1 && id) || val.url === '/admin') { this.showFooter = true; }
              else {
                console.log(val.url);
                router.navigate(['login']);
                auth.logOff();
              }
            }
          } else {
            this.showFooter = false;
          }
        });
      }
    });

    AppComponent.menuClick.subscribe(hasClicked => {
      console.log(hasClicked);
      if (hasClicked) {
        this.navigate([], ['.fullMenu'], 200);
      } else {
        this.navigate(['.fullMenu'], [], 200);
      }
      this.menuIsOpen = hasClicked;
    });
  }

  initializeApp() {
    this.platform.ready().then(() => {
      this.statusBar.styleDefault();
      this.splashScreen.hide();
      this.checkCodePush();
    });
  }

  checkCodePush() {
    Loading.hourglass('', { svgColor: '#00B0F0' });
    const att = (downloadProgress) => {
      console.log(downloadProgress);
      this.progress = (downloadProgress.receivedBytes * 100) / downloadProgress.totalBytes;
    };

    this.codePush.sync({
      updateDialog: {
        appendReleaseDescription: true,
        descriptionPrefix: '\n\nUpdates:\n',
        mandatoryContinueButtonLabel: 'Ok',
        mandatoryUpdateMessage: `
            Clique no botão abaixo para receber a mais nova versão do aplicativo.
            Haga clic en el botón de abajo para recibir la versión más reciente de la aplicación.
            Click on the button below to receive the newest version of the app.
            `,
        updateTitle: '',
        optionalIgnoreButtonLabel: 'X',
        optionalInstallButtonLabel: 'Ok',
        optionalUpdateMessage: `
            Clique no botão abaixo para receber a mais nova versão do aplicativo.\n
            Haga clic en el botón de abajo para recibir la versión más reciente de la aplicación.\n
            Click on the button below to receive the newest version of the app.
            `
      },
      installMode: InstallMode.IMMEDIATE
    }, att).subscribe(
      (data: SyncStatus) => {
        console.log('CODE PUSH SUCCESSFUL: ' + data);
        if (data === 0) {
          Loading.remove();
        }
      },
      (err) => {
        console.log('CODE PUSH ERROR: ' + err);
        Loading.remove();
      }
    );

  }

  tabClick(n) {
    this.tabActive = n;

  }

  downloadPdf() {
    if (this.userData.userType === 'Atendente' || this.userData.userType === 'StationAdmin') {
      window.open('https://firebasestorage.googleapis.com/v0/b/almax---prd.appspot.com/o/atendente.pdf?alt=media&token=89ea325a-8602-4e39-b2aa-bd16773cfe14');
    } else {
      window.open('https://firebasestorage.googleapis.com/v0/b/almax---prd.appspot.com/o/cliente.pdf?alt=media&token=cbf9fd37-4c6a-4899-a50b-15e3085e380a');
    }
  }

  async showToast() {
    // let toast = await this.toastCtrl.create({
    //   message: 'Essa funcionalidade estará pronta em breve!',
    //   duration: 2000,
    //   position: "top"
    // });
    // toast.present()
  }

  navigate(from: any, to: any, duration: number) {
    if (from === 'route') {
      this.router.navigate([to]);
      this.navigate(['.fullMenu'], [], 200);
    }
    else {
      from.forEach(itemClass => {
        $(itemClass).fadeOut(duration);
      });
      setTimeout(() => {
        to.forEach(itemClass => {
          $(itemClass).fadeIn(duration);
        });
      }, duration);
    }
  }

  logOut() {
    this.navigate(['.fullMenu'], [], 200);
    this.auth.logOff();
  }

  starNote(n) {
    this.starRating = n;
  }
  nextStep() {
    this.loading = true;
    this.idGeneralRating = (new Date().getTime()).toString() + (Math.floor(100000 + Math.random() * 900000)).toString();
    this.db.collection('ratingAnswers').doc(this.idGeneralRating).set({
      id: this.idGeneralRating,
      userId: this.userData.id,
      userType: this.userData.userType,
      createdAt: new Date(),
      ratingId: 'generalRating',
      question: 'Avaliação geral',
      rating: this.starRating
    }).then(() => {
      this.db.collection('users').doc(this.userData.id).update({ hasRated: true });
      this.isNext = true;
      this.loading = false;
    }).catch(() => {
      alert('Erro ao salvar avaliação. Verfique sua conexão e tente novamente.');
      this.loading = false;
    });
  }
  answerNote1(n) {
    this.answerRating1 = n;
  }
  answerNote2(n) {
    this.answerRating2 = n;
  }
  answerNote3(n) {
    this.answerRating3 = n;
  }
  answerNote4(n) {
    this.answerRating4 = n;
  }
  lastStep() {
    if (this.answerRating1 > 0) {
      this.idGeneralRating = (new Date().getTime()).toString() + (Math.floor(100000 + Math.random() * 900000)).toString();
      this.db.collection('ratingAnswers').doc(this.idGeneralRating).set({
        id: this.idGeneralRating,
        userId: this.userData.id,
        userType: this.userData.userType,
        createdAt: new Date(),
        ratingId: this.answerArr[0].id,
        question: this.answerArr[0].text,
        rating: this.answerRating1
      });
    }
    if (this.answerRating2 > 0) {
      this.idGeneralRating = (new Date().getTime()).toString() + (Math.floor(100000 + Math.random() * 900000)).toString();
      this.db.collection('ratingAnswers').doc(this.idGeneralRating).set({
        id: this.idGeneralRating,
        userId: this.userData.id,
        userType: this.userData.userType,
        createdAt: new Date(),
        ratingId: this.answerArr[1].id,
        question: this.answerArr[1].text,
        rating: this.answerRating2
      });
    }
    if (this.answerRating3 > 0) {
      this.idGeneralRating = (new Date().getTime()).toString() + (Math.floor(100000 + Math.random() * 900000)).toString();
      this.db.collection('ratingAnswers').doc(this.idGeneralRating).set({
        id: this.idGeneralRating,
        userId: this.userData.id,
        userType: this.userData.userType,
        createdAt: new Date(),
        ratingId: this.answerArr[2].id,
        question: this.answerArr[2].text,
        rating: this.answerRating3
      });
    }
    if (this.answerRating4 > 0) {
      this.idGeneralRating = (new Date().getTime()).toString() + (Math.floor(100000 + Math.random() * 900000)).toString();
      this.db.collection('ratingAnswers').doc(this.idGeneralRating).set({
        id: this.idGeneralRating,
        userId: this.userData.id,
        userType: this.userData.userType,
        createdAt: new Date(),
        ratingId: this.answerArr[3].id,
        question: this.answerArr[3].text,
        rating: this.answerRating4
      });
    }
    this.isNext = false;
    this.isPopup = false;
  }
  closePopup() {
    this.isNext = false;
    this.isPopup = false;
  }

}
