import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private db: AngularFirestore) { }

  public getUser(id: string): any {
    return this.db.collection('users').doc(id).ref.get();
  }

  public updateUser(id: string, patch: any): Promise<void> {
    return this.db.collection('users').doc(id).update(patch);
  }

}
