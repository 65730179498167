import { environment } from './../../environments/environment';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { ToastController, Platform } from '@ionic/angular';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor(
    private toastController: ToastController,
    private platform: Platform,
    private router: Router
  ) {
  }

  strTrim(str: string) {
    const comAcento = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';
    const semAcento = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';
    let novastr = '';
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < comAcento.length; a++) {
        if (str.substr(i, 1) === comAcento.substr(a, 1)) {
          novastr += semAcento.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca === false) { novastr += str.substr(i, 1); }
    }
    novastr = novastr.replace(/_/g, '').replace(/ /g, '').replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '').replace(/[{()}]/g, '');
    return novastr.toLowerCase();
  }

  async createToast(message, duration) {
    const toast = await this.toastController.create({
      message,
      duration
    });
    toast.present();
  }






  // Métodos de CPF e CNPJ ========================================================
  isCpfOrCnpj(valor) {
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, '');
    if (valor.length === 11) {
      return 'CPF';
    } else if (valor.length === 14) {
      return 'CNPJ';
    } else {
      return false;
    }
  }

  calculoDeVerifCpfCnpj(digitos, posicoes = 10, somaDigitos = 0) {
    digitos = digitos.toString();
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < digitos.length; i++) {
      somaDigitos = somaDigitos + (digitos[i] * posicoes);
      posicoes--;
      if (posicoes < 2) {
        posicoes = 9;
      }
    }
    somaDigitos = somaDigitos % 11;
    if (somaDigitos < 2) {
      somaDigitos = 0;
    } else {
      somaDigitos = 11 - somaDigitos;
    }
    const cpf = digitos + somaDigitos;
    return cpf;
  }

  validateCpf(valor) {
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, '');
    const digitos = valor.substr(0, 9);
    let novoCpf = this.calculoDeVerifCpfCnpj(digitos);
    novoCpf = this.calculoDeVerifCpfCnpj(novoCpf, 11);
    if (novoCpf === valor) {
      return true;
    } else {
      return false;
    }
  }

  validateCnpj(valor) {
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, '');
    const cnpjOriginal = valor;
    const primeirosNumerosCNPJ = valor.substr(0, 12);
    const primeiroCalculo = this.calculoDeVerifCpfCnpj(primeirosNumerosCNPJ, 5);
    const segundoCalculo = this.calculoDeVerifCpfCnpj(primeiroCalculo, 6);
    const cnpj = segundoCalculo;
    if (cnpj === cnpjOriginal) {
      return true;
    }
    return false;
  }

  validateCpfAndCnpj(valor) {
    const valida = this.isCpfOrCnpj(valor);
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, '');
    if (valida === 'CPF') {
      return this.validateCpf(valor);
    } else if (valida === 'CNPJ') {
      return this.validateCnpj(valor);
    } else {
      return false;
    }
  }

  formatCpfAndCnpj(valor) {
    let formatado = null;
    const valida = this.isCpfOrCnpj(valor);
    valor = valor.toString();
    valor = valor.replace(/[^0-9]/g, '');
    if (valida === 'CPF') {
      if (this.validateCpf(valor)) {
        formatado = valor.substr(0, 3) + '.';
        formatado += valor.substr(3, 3) + '.';
        formatado += valor.substr(6, 3) + '-';
        formatado += valor.substr(9, 2) + '';
      }
    } else if (valida === 'CNPJ') {
      if (this.validateCnpj(valor)) {
        formatado = valor.substr(0, 2) + '.';
        formatado += valor.substr(2, 3) + '.';
        formatado += valor.substr(5, 3) + '/';
        formatado += valor.substr(8, 4) + '-';
        formatado += valor.substr(12, 14) + '';
      }
    }
    return formatado;
  }
  // Métodos de CPF e CNPJ ========================================================




  // Métodos de data ==============================================================

  getGapBetweenDays(start, end) {
    const dateInit = new Date(start).getTime();
    const dateEnd = new Date(end).getTime();
    const gap = dateEnd - dateInit;
    return parseFloat((gap / (24 * 60 * 60 * 1000)).toFixed(1));
  }

  getDateAfterDaysGap(start, gapInDays) {
    const dateInit = new Date(start).getTime();
    return new Date(dateInit + gapInDays * 24 * 60 * 60 * 1000);
  }

  // Métodos de data ==============================================================




  // Métodos de plataforma ==============================================================

  //plataforma é web e/ou mobileweb
  platformIsWeb() {
    return (this.platform.is('mobileweb') || this.platform.is('desktop'));
  }

  //plataforma é mobile e/ou mobileweb
  platformIsMobile() {
    return (this.platform.is('mobile'));
  }

  //redirecionar usuário à pag de envio de nota (web) se a plataforma for web
  redirectIfWeb() {
    // if(this.platformIsWeb() && !environment.forceMobileLogin){
    //   this.router.navigate(['enviar-nota-web']);
    // }
  }

  // Métodos de plataforma ==============================================================



}
