import { UtilsService } from './utils.service';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Router } from '@angular/router';
import { LoadingController } from '@ionic/angular';
import { HttpClient } from '@angular/common/http';
import { UserService } from './user.service';
import { BehaviorSubject, Subscription } from 'rxjs';
import { promise } from 'protractor';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  public gotUserData = false;
  public idUser = '';
  public expiringPoints = 0;

  constructor(
    private fireAuth: AngularFireAuth,
    private router: Router,
    private loading: LoadingController,
    private http: HttpClient,
    private userS: UserService,
    private db: AngularFirestore,
    private utils: UtilsService) {

    this.fireAuth.onAuthStateChanged((user) => {
      if (!user) {
        this.logOff();
      } else if (user) {
        this.getAuthToken().then(t => {
          this.http.post(environment.engineEndpoint + '/user/expires', { cpf: user.email.split('@')[0] },
            { headers: { authorization: 'Bearer ' + t } }).toPromise().then((pointsReponse: any) => {
              console.log(pointsReponse);
              this.expiringPoints = pointsReponse.data.points;
            });
        });
      }
    });
  }

  public async getAuthToken() {
    return (await this.fireAuth.currentUser).getIdToken();
  }

  public async getUserData() {
    return new Promise((resolve, reject) => {
      this.fireAuth.onAuthStateChanged(async (user) => {
        if (user) {
          this.userS.getUser(user.email.split('@')[0]).then((userData) => {
            resolve(userData.data());
          });
        }
      });
    });
  }

  public async signUp(userData, password) {
    const loading = await this.loading.create({
      message: 'Aguarde...'
    });
    await loading.present();
    return new Promise((resolve, reject) => {
      this.gotUserData = true;
      const credentials = userData.id + '@almax.com';
      this.fireAuth.createUserWithEmailAndPassword(credentials, password)
        .then(() => {
          this.db.collection('users').doc(userData.id).set(userData).then(() => {
            loading.dismiss();
            this.router.navigate(['home']);
            resolve(true);
          })
            .catch((err) => {
              reject(err);
            });
        })
        .catch(err => {
          if (err.code === 'auth/email-already-in-use') {
            loading.dismiss();
            reject('Este CPF já foi cadastrado.');
          } else if (err.code === 'auth/weak-password') {
            reject('Senha fraca.');
            loading.dismiss();
          } else {
            loading.dismiss();
            reject(err);
          }
        });
    });
  }

  public async signUpClient(userData) {
    const loading = await this.loading.create({
      message: 'Aguarde...'
    });
    await loading.present();
    return new Promise((resolve, reject) => {
      this.http.post('https://us-central1-almax---prd.cloudfunctions.net/engine/user', userData)
        .toPromise().then((result: any) => {
          loading.dismiss();
          if (result.status === 200) {
            resolve('');
          } else {
            reject();
          }
        }).catch((err) => {
          console.log(err);
          loading.dismiss();
          reject();
        });
    });
  }

  public async logIn(credential, password) {
    const loading = await this.loading.create({
      message: 'Aguarde...'
    });
    await loading.present();
    const id = credential;
    credential = this.strTrim(credential) + '@almax.com';
    return new Promise((resolve, reject) => {
      this.gotUserData = true;
      this.fireAuth.signInWithEmailAndPassword(credential, password)
        .then((user) => {
          this.userS.getUser(id)
            .then(async userDoc => {
              await this.createAccessLog(userDoc.data());
              this.router.navigate(['home']);
              loading.dismiss();
              resolve(true);
            }).catch(err => console.log(err));
        })
        .catch((err) => {
          loading.dismiss();
          if (err.code === 'auth/user-disabled') {
            reject('Este usuário foi desabilitado');
          } else if (err.code === 'auth/user-not-found') {
            reject('Usuário não encontrado');
          } else if (err.code === 'auth/wrong-password') {
            reject('Credenciais inválidas');
          } else {
            reject('Ocorreu um erro ao fazer login');
          }
        });
    });
  }

  public async logInWeb(credential, password) {
    const loading = await this.loading.create({
      message: 'Aguarde...'
    });
    await loading.present();
    const id = credential;
    credential = this.strTrim(credential) + '@almax.com';
    return new Promise((resolve, reject) => {
      this.gotUserData = true;
      this.fireAuth.signInWithEmailAndPassword(credential, password)
        .then((user) => {
          this.userS.getUser(id)
            .then(async userDoc => {
              if (userDoc.data().userType !== 'Atendente') {
                this.logOff();
                loading.dismiss();
                reject('Área disponível apenas para Atendentes');
              } else {
                await this.createAccessLog(userDoc.data());
                if (environment.forceMobileLogin) {
                  this.router.navigate(['home']);
                } else {
                  this.router.navigate(['enviar-nota-web']);
                }
                loading.dismiss();
                resolve(true);
              }
            }).catch(err => console.log(err));
        })
        .catch((err) => {
          loading.dismiss();
          if (err.code === 'auth/user-disabled') {
            reject('Este usuário foi desabilitado');
          } else if (err.code === 'auth/user-not-found') {
            reject('Usuário não encontrado');
          } else if (err.code === 'auth/wrong-password') {
            reject('Credenciais inválidas');
          } else {
            reject('Ocorreu um erro ao fazer login');
          }
        });
    });
  }

  logOff() {
    this.fireAuth.signOut();
    if (this.router.routerState.snapshot.url !== '/splash' && this.router.routerState.snapshot.url !== '/admin') {
      this.router.navigate(['login']);
    }
  }

  //Criar log de acesso do user
  async createAccessLog(userDoc) {
    try {
      const dt = new Date();
      const log = {
        id: dt.getTime().toString(),
        date: dt,
        user: userDoc.id,
        userType: userDoc.userType,
        device: this.utils.platformIsMobile() ? 'Mobile' : 'Desktop'
      };
      return await this.db.collection('accessLogs').doc(log.id).set(log);
    } catch (err) {
      console.log(err);
      return;
    }
  }

  strTrim(str: string) {
    const comAcento = 'ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ';
    const semAcento = 'AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr';
    let novastr = '';
    for (let i = 0; i < str.length; i++) {
      let troca = false;
      for (let a = 0; a < comAcento.length; a++) {
        if (str.substr(i, 1) === comAcento.substr(a, 1)) {
          novastr += semAcento.substr(a, 1);
          troca = true;
          break;
        }
      }
      if (troca === false) { novastr += str.substr(i, 1); }
    }
    novastr = novastr.replace(/_/g, '').replace(/ /g, '').replace(/\./g, '').replace(/\-/g, '').replace(/\//g, '').replace(/[{()}]/g, '');
    return novastr.toLowerCase();
  }
}


