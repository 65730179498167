import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'fixed'
})
export class FixedPipe implements PipeTransform {

    transform(points: number): any {
        // Retorna o primeiro a o último nome do usuário.
        return points.toFixed(2);
    }

}
