// System
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { HttpClientModule } from '@angular/common/http';

// App
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { environment } from '../environments/environment';

//Firebase
import { AngularFireModule } from '@angular/fire/compat/';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';


// Providers
import { Camera } from '@ionic-native/camera/ngx';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';
import { PipesModule } from './pipes/pipes.module';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { CodePush } from '@ionic-native/code-push/ngx';

@NgModule({
  declarations: [AppComponent],
  entryComponents: [],
  imports: [
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFirestoreModule,
    AngularFireAuthModule,
    HttpClientModule,
    PipesModule,
    NgxMaskIonicModule.forRoot()
  ],
  providers: [
    StatusBar,
    Camera,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    SplashScreen,
    CodePush
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
